import React from "react"
import Kiosk from "../images/productiveLoan.png"

import "../styles/PageNotFound.scss"

export default function PageNotFound() {
  return (
    <main className="page-not-found-container">
      <div className="image-container">
        <img src={Kiosk} alt="Illustrated kiosk with owner sitting inside" />
      </div>
      <div className="content-container">
        <h1>404</h1>
        <p>Page Not Found</p>
        <a href="/home">Back to home</a>
      </div>
    </main>
  )
}
